import PieChart from "@/components/charts/pie-chart";
import { Layout, LayoutBody } from "@/components/custom/layout";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import useDashboardStore from "@/redux/slice/dashboardSlice";
import useTradeStore from "@/redux/slice/tradeSlice";
import { IconCurrencyDollar } from "@tabler/icons-react";
import React from "react";
import { AccountBalanceChart } from "./components/account-balance-chart";
import { useColumns } from "./components/columns";
import { DataTable } from "./components/data-table";
import DistributionChart from "./components/distribution-chart";
import { ProfitLossChart } from "./components/profit-loss-chart";

export default function Trades() {
	const columns = useColumns();
	const { trades, loading, error, fetchAllTrades } = useTradeStore();
	const {
		dashboardData,
		loading: dashboardLoading,
		error: dashboardError,
		fetchDashboardData,
	} = useDashboardStore();
	React.useEffect(() => {
		fetchDashboardData();
		fetchAllTrades();
	}, [fetchAllTrades]);

	if (loading || dashboardLoading) {
		return <div>Loading...</div>; // Or a more sophisticated loading component
	}

	if (error || dashboardError) {
		return <div>Error: {error}</div>; // Or a more user-friendly error component
	}

	const biggestWin = trades.reduce(
		(max, trade) => (trade?.profit > max?.profit ? trade : max),
		trades[0]
	);
	const biggestLoss = trades.reduce(
		(min, trade) => (trade?.profit < min?.profit ? trade : min),
		trades[0]
	);

	return (
		<Layout>
			{/* ===== Top Heading ===== */}
			<head>
				<script src="/wails/ipc.js"></script>
				<script src="/wails/runtime.js"></script>
			</head>

			{/* ===== Main ===== */}
			<LayoutBody className="space-y-2">
				<div className="flex items-center justify-between space-y-2">
					<h1 className="text-2xl font-bold tracking-tight md:text-3xl">
						Your Trades
					</h1>
					{/* <div className="flex items-center space-x-2">
            <Button>Download</Button>
          </div> */}
				</div>
				<div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-4">
					<Card className="h-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
							<CardTitle className="text-sm font-medium">Trade PnL</CardTitle>
							<IconCurrencyDollar className="h-4 w-4 text-muted-foreground" />
						</CardHeader>
						<CardContent>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Gross PnL</div>
								<div
									className={`text-sm font-bold ${
										dashboardData?.pnl > 0
											? "text-emerald-500"
											: "text-rose-500"
									}`}
								>
									${dashboardData?.pnl?.toFixed(2)}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Net PnL</div>
								<div
									className={`text-sm font-bold ${
										dashboardData?.pnl > 0
											? "text-emerald-500"
											: "text-rose-500"
									}`}
								>
									${dashboardData?.gross_profit?.toFixed(2)}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Total Trades</div>
								<div className="text-sm font-bold">
									{dashboardData?.total_trades}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Wining Trades</div>
								<div className="text-sm font-bold">
									{dashboardData?.total_wins}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Losing Trades</div>
								<div className="text-sm font-bold">
									{dashboardData?.total_losses}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Pairs Trades</div>
								<div className="text-sm font-bold">
									{dashboardData?.distribution?.length}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Most Profitable Pair</div>
								<div className="text-sm font-bold">
									{dashboardData?.most_profitable_symbol}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Least Profitable Pair</div>
								<div className="text-sm font-bold">
									{dashboardData?.most_lossable_symbol}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Biggest Win</div>
								<div className="text-sm font-bold text-emerald-500">
									{biggestWin?.profit}
								</div>
							</div>
							<div className="flex flex-row items-center justify-between">
								<div className="text-sm">Biggest Loss</div>
								<div className="text-sm font-bold text-rose-500">
									{biggestLoss?.profit}
								</div>
							</div>
						</CardContent>
					</Card>
					<div>
						<AccountBalanceChart data={dashboardData} />
					</div>
					<div>
						<ProfitLossChart data={trades} />
					</div>
					<Card className="h-full w-full">
						<CardContent className="flex flex-col gap-6 py-6">
							<div className="flex flex-col sm:flex-row justify-between gap-6">
								<div className="relative w-full sm:w-1/2 flex justify-center">
									<div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center ">
										<div className="text-lg font-bold">
											{dashboardData?.profit_factor?.toFixed(2)}
										</div>
										<div className="text-xs text-muted-foreground">
											Profit Factor
										</div>
									</div>
									<PieChart
										angles={[180, 0]}
										radii={[55, 70]}
										data={dashboardData?.profit_loss || []}
									/>
								</div>
								<div className="relative w-full sm:w-1/2 flex justify-center">
									<div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
										<div className="text-lg font-bold">
											{dashboardData?.win_rate?.toFixed(2)}%
										</div>
										<div className="text-xs text-muted-foreground">
											Win Rate
										</div>
									</div>
									<PieChart
										angles={[180, 0]}
										radii={[55, 70]}
										data={dashboardData?.win_loss || []}
									/>
								</div>
							</div>
							<div className="relative w-full sm:w-1/2 flex justify-center">
								<div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
									<div className="text-lg font-bold">
										{dashboardData?.distribution?.length}
									</div>
									<div className="text-xs text-muted-foreground">
										Symbols Traded
									</div>
								</div>
								<DistributionChart
									angles={[180, 0]}
									radii={[50, 70]}
									data={dashboardData?.distribution || []}
								/>
							</div>
						</CardContent>
					</Card>
				</div>
				{/* <DistributionChart /> */}
				<div className="grid grid-cols-1 gap-2 lg:grid-cols-8">
					<Card className="col-span-1 max-h-full lg:col-span-8">
						<div className="w-full overflow-auto">
							<DataTable data={trades} columns={columns} />
						</div>
					</Card>
				</div>
			</LayoutBody>
		</Layout>
	);
}
