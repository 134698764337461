import { controller } from "@/types/interfaces";

let API_BASE_URL: string | undefined;

console.log("Current NODE_ENV:", import.meta.env.MODE);

// In Vite, environment variables are prefixed with VITE_
console.log("VITE_BASEURL from env:", import.meta.env.VITE_BASEURL);

API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/trades` : undefined;

console.log("Constructed API_BASE_URL:", API_BASE_URL);

if (!API_BASE_URL) {
	console.warn("VITE_BASEURL is not defined in environment variables");
}

export async function GetDashboardData(): Promise<controller.DashboardData> {
	if (!API_BASE_URL) {
		console.error(
			"API_BASE_URL is undefined. VITE_BASEURL:",
			import.meta.env.VITE_BASEURL
		);
		throw new Error(
			"API_BASE_URL is not defined. Check your environment variables."
		);
	}

	try {
		console.log("Fetching from:", `${API_BASE_URL}/dashboard`);
		const response = await fetch(`${API_BASE_URL}/dashboard`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const dashboard = await response.json();
		console.log(dashboard, "dashboard data");
		return dashboard.data as controller.DashboardData;
	} catch (error) {
		console.error("Error fetching dashboard data:", error);
		throw error;
	}
}
