import { Layout, LayoutBody, LayoutHeader } from "@/components/custom/layout";
import { Search } from "@/components/search";
import ThemeSwitch from "@/components/theme-switch";
import { Separator } from "@/components/ui/separator";
import { UserNav } from "@/components/user-nav";
import {
	IconBrowserCheck,
	IconNotification,
	IconPalette,
	IconTool,
	IconUser,
} from "@tabler/icons-react";
import { Outlet } from "react-router-dom";
import SidebarNav from "./components/sidebar-nav";

export default function Settings() {
	return (
		<Layout fadedBelow fixedHeight>
			{/* ===== Top Heading ===== */}

			<LayoutBody className="flex flex-col" fixedHeight>
				<div className="space-y-0.5">
					<h1 className="text-2xl font-bold tracking-tight md:text-3xl">
						Settings
					</h1>
					<p className="text-muted-foreground">
						Manage your account settings and set e-mail preferences.
					</p>
				</div>
				<Separator className="my-6" />
				<div className="flex flex-1 flex-col space-y-8 overflow-auto lg:flex-row lg:space-x-12 lg:space-y-0">
					{/* <aside className="sticky top-0 lg:w-1/5">
						<SidebarNav items={sidebarNavItems} />
					</aside> */}
					<div className="w-full p-1 pr-4 ">
						<div className="pb-16">
							<Outlet />
						</div>
					</div>
				</div>
			</LayoutBody>
		</Layout>
	);
}

const sidebarNavItems = [
	{
		title: "Profile",
		icon: <IconUser size={18} />,
		href: "/settings",
	},
	{
		title: "Account",
		icon: <IconTool size={18} />,
		href: "/settings/account",
	},
	{
		title: "Appearance",
		icon: <IconPalette size={18} />,
		href: "/settings/appearance",
	},
	{
		title: "Notifications",
		icon: <IconNotification size={18} />,
		href: "/settings/notifications",
	},
	{
		title: "Display",
		icon: <IconBrowserCheck size={18} />,
		href: "/settings/display",
	},
	// {
	//   title: "Error Example",
	//   icon: <IconExclamationCircle size={18} />,
	//   href: "/settings/error-example",
	// },
];
