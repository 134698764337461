import { Button } from "@/components/custom/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import useBackTestStore from "@/redux/slice/backTestSlice";
import { TrashIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
interface DataTableRowActionsProps<TData> {
	row: Row<TData extends { uuid: string } ? TData : never>;
}

export function DataTableRowActions<TData>({
	row,
}: // row,

DataTableRowActionsProps<TData>) {
	const { deleteBackTest } = useBackTestStore();
	const handleDelete = (uuid: string) => {
		console.log("Deleting", uuid);
		deleteBackTest(uuid);
	};

	return (
		<>
			<Dialog>
				<DialogTrigger asChild>
					<Button
						size={"icon"}
						variant={"ghost"}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<TrashIcon className="h-4 w-4 text-red-700" />
					</Button>
				</DialogTrigger>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Are you sure?</DialogTitle>
						<DialogDescription>
							This action cannot be undone. This will permanently delete the
							test.
						</DialogDescription>
					</DialogHeader>
					<DialogClose asChild>
						<Button
							// className="flex items-center justify-center"
							variant="destructive"
							onClick={() => {
								handleDelete(row.getValue("uuid"));
							}}
						>
							Delete
						</Button>
					</DialogClose>
				</DialogContent>
			</Dialog>
		</>
	);
}
