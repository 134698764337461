import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "@/components/ui/checkbox";
import { models } from "@/types/interfaces";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Badge } from "@/components/ui/badge";
import { formatDistanceStrict } from "date-fns";

// import { BackTest } from "../../models/BackTest";

function formatDate(date: string | number | Date): string {
	return new Date(date).toLocaleString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
		// hour: "numeric",
		// minute: "2-digit",
		// hour12: true,
	});
}

function calculateTradeDuration(
	openDate: string | number | Date,
	closeDate: string | number | Date
): string {
	try {
		return formatDistanceStrict(new Date(openDate), new Date(closeDate));
	} catch (error) {
		return "N/A";
	}
}

export const useColumns = (): ColumnDef<models.Trade>[] => {
	return [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => {
						table.toggleAllPageRowsSelected(!!value);
					}}
					aria-label="Select all"
					className="translate-y-[2px]"
					onClick={(e) => e.stopPropagation()}
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						row.toggleSelected(!!value);
					}}
					aria-label="Select row"
					className="translate-y-[2px]"
					onClick={(e) => e.stopPropagation()}
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: "open_date",
			id: "open_date",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Date" />
			),
			cell: ({ row }) => {
				return (
					<div>
						<span>{formatDate(row.original?.open_date)}</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "symbol",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Symbol" />
			),
			cell: ({ row }) => {
				return (
					<div className="flex space-x-2">
						<span className="max-w-32 truncate font-medium sm:max-w-72 md:max-w-[31rem]">
							{row.getValue("symbol")}
						</span>
					</div>
				);
			},
		},
		{
			accessorKey: "profit",
			id: "result",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => {
				return (
					<Badge
						variant={
							Number(row.original?.profit?.toFixed(3)) > 0
								? "constructive"
								: "destructive"
						}
					>
						{row.original?.profit > 0 ? "Win" : "Loss"}
					</Badge>
				);
			},
		},
		{
			accessorKey: "trade_type",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Side" />
			),
			cell: ({ row }) => {
				return (
					<div className="flex items-center capitalize">
						<Badge
							variant={
								row.getValue("trade_type") === "buy"
									? "constructive"
									: "destructive"
							}
						>
							<span>{row.getValue("trade_type")}</span>
						</Badge>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "profit",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Profit/Loss" />
			),
			cell: ({ row }) => {
				const profit = Number(row.original?.profit?.toFixed(5));
				return (
					<div
						className={`${
							profit > 0 ? "text-emerald-500" : "text-rose-500"
						} font-bold text-center text-sm`}
					>
						{profit}
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "volume",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Volume" />
			),
			cell: ({ row }) => {
				return (
					<div className="flex items-center">
						<span>{row.original?.volume?.toFixed(4)}</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "avg_buy_price",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Open Price" />
			),
			cell: ({ row }) => {
				return (
					<div>
						<span>{row.original?.avg_buy_price?.toFixed(5)}</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "avg_sell_price",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Close Price" />
			),
			cell: ({ row }) => {
				return (
					<div>
						<span>{row.original?.avg_sell_price?.toFixed(5)}</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "open_date",
			id: "open_time",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Open Time" />
			),
			cell: ({ row }) => {
				return (
					<div>
						<span>
							{new Date(row.original?.open_date).toLocaleTimeString()}
						</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "close_date",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Close Time" />
			),
			cell: ({ row }) => {
				return (
					<span>{new Date(row.original?.close_date).toLocaleTimeString()}</span>
				);
			},
		},
		{
			accessorKey: "duration",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Duration" />
			),
			cell: ({ row }) => {
				return (
					<span>
						{calculateTradeDuration(
							row.original?.open_date,
							row.original?.close_date
						)}
					</span>
				);
			},
		},

		{
			accessorKey: "profit_target",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="TP" />
			),
			cell: ({ row }) => {
				const profitTarget = row.original?.profit_target?.toFixed(5);
				return (
					<>
						<span
							className={`${
								row.original.tp_hit ? "text-emerald-500 font-bold" : ""
							}`}
						>
							{`${
								profitTarget == "0.000" ? "NA" : Number(profitTarget).toString()
							}`}
						</span>
						{/* <span> */}

						{/* </span> */}
					</>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "stop_loss",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="SL" />
			),
			cell: ({ row }) => {
				const stopLoss = row.original?.stop_loss?.toFixed(5);
				return (
					<div
						className={`${
							row.original.sl_hit ? "text-rose-500 font-bold" : ""
						}`}
					>
						<span>{`${
							stopLoss == "0.000" ? "NA" : Number(stopLoss).toString()
						}`}</span>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},

		{
			accessorKey: "strategy",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Strategy" />
			),
			cell: ({ row }) => {
				const profit = Number(row.original?.profit?.toFixed(3));
				return (
					<div>
						<Badge>{`Strategy 01`}</Badge>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "mistakes",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Mistakes" />
			),
			cell: ({ row }) => {
				const profit = Number(row.original?.profit?.toFixed(3));
				return (
					<div>
						<Badge>{`Mistake 01`}</Badge>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			cell: ({ row }) => <DataTableRowActions row={row} />,
		},
	];
};
