"use client";

import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components//ui/chart";
import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts";

interface SingleBarChartProps {
	avgWin: number;
	avgLoss: number;
}

export default function SingleBarChart({
	avgWin,
	avgLoss,
}: SingleBarChartProps) {
	const data = [
		{
			name: "Average win/loss",
			value: avgWin,
			value2: avgLoss,
		},
	];
	return (
		<ChartContainer
			config={{
				value: {
					label: "Avg win",
					color: "hsl(var(--muted))",
				},
				value2: {
					label: "Avg loss",
					color: "hsl(var(--muted))",
				},
			}}
			className="aspect-auto h-[24px] w-full mb-2"
		>
			<BarChart
				// width={500}
				layout="vertical"
				// height={300}
				data={data}
				margin={{
					top: 0,
					right: 0,
					left: 0,
					bottom: 5,
				}}
			>
				<XAxis type="number" hide domain={[0, 100]} />
				<YAxis dataKey="data" type="category" hide />
				<ChartTooltip
					defaultIndex={2}
					content={
						<ChartTooltipContent
							// hideIndicator
							cursor={false}
							className="w-40 bg-background/45 backdrop-blur-md"
						/>
					}
					cursor={false}
				/>
				{/* <Tooltip /> */}
				<Bar
					dataKey="value"
					stackId="a"
					fill="hsl(var(--chart-red))"
					radius={[5, 0, 0, 5]}
				/>
				<Bar
					dataKey="value2"
					stackId="a"
					fill="hsl(var(--chart-green))"
					radius={[0, 5, 5, 0]}
				/>
				<LabelList
					position="insideLeft"
					dataKey="date"
					offset={8}
					fontSize={12}
					fill="hsl(var(--muted-foreground))"
				/>
			</BarChart>
		</ChartContainer>
	);
}
