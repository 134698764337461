import { create } from "zustand";
import { models } from "@/types/interfaces";
import {
	GetAllBackTests,
	GetBackTestByUUID,
	CreateBackTest,
	UpdateBackTest,
	DeleteBackTestByUUID,
	AddBreakEvenTrade,
	AddLossTrade,
	AddProfitTrade,
	DeleteMultipleBackTestsByUUID,
} from "@/api-methods/back-test-controller";

interface BackTestState {
	backTests: models.BackTest[];
	backTest: models.BackTest | null;
	error: string | undefined;
	loading: boolean;
	isFetching: boolean;
	lastCreatedBackTest: models.BackTest | null;
	success: boolean;
	fetchAllBackTests: () => Promise<void>;
	fetchBackTest: (uuid: string) => Promise<void>;
	createBackTest: (
		backTest: models.BackTest
	) => Promise<models.BackTest | undefined>;
	updateBackTest: (backTest: models.BackTest) => Promise<void>;
	deleteBackTest: (uuid: string) => Promise<void>;
	deleteMultipleBackTests: (uuids: string[]) => Promise<void>;
	addProfitTrade: (trade: models.BackTestTrades, uuid: string) => Promise<void>;
	addLossTrade: (trade: models.BackTestTrades, uuid: string) => Promise<void>;
	addBreakEvenTrade: (
		trade: models.BackTestTrades,
		uuid: string
	) => Promise<void>;
}

const useBackTestStore = create<BackTestState>((set, get) => ({
	backTests: [],
	backTest: null,
	error: undefined,
	loading: false,
	isFetching: false,
	lastCreatedBackTest: null,
	success: false,

	fetchAllBackTests: async () => {
		const { isFetching } = get();
		if (isFetching) return;

		set({ loading: true, isFetching: true });
		try {
			const response = await GetAllBackTests();
			set({
				backTests: response,
				loading: false,
				error: undefined,
				success: true,
				isFetching: false,
			});
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	fetchBackTest: async (uuid: string) => {
		const { isFetching } = get();
		// if (isFetching) return;

		set({ loading: true, isFetching: true });
		try {
			const response = await GetBackTestByUUID(uuid);
			set({ backTest: response });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	createBackTest: async (
		backTest: models.BackTest
	): Promise<models.BackTest | undefined> => {
		console.log(backTest, "backtest creating");
		// const { isFetching } = get();
		// if (isFetching) return;

		set({ loading: true, isFetching: true });
		try {
			const response = await CreateBackTest(backTest);
			set((state) => ({
				backTests: [...state.backTests, response],
				lastCreatedBackTest: response,
			}));
			console.log(response, "backtest created");
			return response;
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
			return undefined;
		}
	},

	updateBackTest: async (backTest: models.BackTest) => {
		const { isFetching } = get();

		set({ loading: true, isFetching: true });
		try {
			const response = await UpdateBackTest(backTest);
			set((state) => ({
				backTests: state.backTests.map((bt) =>
					bt.uuid === response.uuid ? response : bt
				),
			}));
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	deleteBackTest: async (uuid: string) => {
		set({ loading: true, isFetching: true });
		try {
			await DeleteBackTestByUUID(uuid);
			await get().fetchAllBackTests();
			set({ success: true });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	deleteMultipleBackTests: async (uuids: string[]) => {
		set({ loading: true, isFetching: true });
		try {
			await DeleteMultipleBackTestsByUUID(uuids);
			await get().fetchAllBackTests();
			set({ success: true });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	addProfitTrade: async (trade: models.BackTestTrades, uuid: string) => {
		set({ loading: true, isFetching: true });
		try {
			await AddProfitTrade(trade, uuid);
			await get().fetchBackTest(uuid);
			set({ success: true });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	addLossTrade: async (trade: models.BackTestTrades, uuid: string) => {
		set({ loading: true, isFetching: true });
		try {
			await AddLossTrade(trade, uuid);
			await get().fetchBackTest(uuid);
			set({ success: true });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},

	addBreakEvenTrade: async (trade: models.BackTestTrades, uuid: string) => {
		set({ loading: true, isFetching: true });
		try {
			await AddBreakEvenTrade(trade, uuid);
			await get().fetchBackTest(uuid);
			set({ success: true });
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},
}));

export default useBackTestStore;
