import { create } from "zustand";
import { GetDashboardData } from "@/api-methods/dashboard-controller";
import { controller } from "@/types/interfaces";

interface DashboardState {
	dashboardData: controller.DashboardData;
	error: string | undefined;
	loading: boolean;
	success: boolean;
	fetchDashboardData: () => Promise<void>;
	isFetching: boolean;
}

const useDashboardStore = create<DashboardState>((set, get) => ({
	dashboardData: {} as controller.DashboardData,
	error: undefined,
	loading: false,
	success: false,
	isFetching: false,

	fetchDashboardData: async () => {
		const { isFetching } = get();
		if (isFetching) return;

		set({ loading: true, isFetching: true });
		try {
			const response = await GetDashboardData();
			console.log(response, "response");
			set({
				dashboardData: response,
				loading: false,
				error: undefined,
				success: true,
				isFetching: false,
			});
		} catch (error) {
			set({
				error: (error as Error).message,
				loading: false,
				success: false,
				isFetching: false,
			});
		}
	},
}));

export default useDashboardStore;
