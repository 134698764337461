import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { controller } from "@/types/interfaces";

interface PieChartProps {
	angles: number[];
	data: controller.SymbolDetails[];
	radii: number[];
}

const CustomTooltip = ({
	active,
	payload,
}: {
	active: boolean;
	payload: any[];
}) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip p-2 rounded-md w-40 bg-background/45 backdrop-blur-md">
				<p className="text-xs font-bold">{payload[0].payload.symbol}</p>
				<div>
					<span className="text-xs text-muted-foreground">Profit: </span>
					<span
						className={`text-xs font-bold ${
							payload[0].payload.profit > 0
								? "text-emerald-400"
								: "text-rose-400"
						}`}
					>
						{payload[0].payload.profit}
					</span>
				</div>
				<div>
					<span className="text-xs text-muted-foreground">Trades: </span>
					<span className={`text-xs font-bold `}>
						{payload[0].payload.total} (W){payload[0].payload.win} (L)
						{payload[0].payload.loss}
					</span>
				</div>
			</div>
		);
	}

	return null;
};

const COLORS = ["#FFBB28", "#FF8042", "#0088FE", "#00C49F"];

const chartConfig = {
	desktop: {
		label: "Desktop",
		color: "#2563eb",
	},
	mobile: {
		label: "Mobile",
		color: "#60a5fa",
	},
} satisfies ChartConfig;

const DistributionChart: React.FC<PieChartProps> = ({
	data,
	angles,
	radii,
}) => {
	// Your component logic here
	console.log(data, "data");

	return (
		<ChartContainer config={chartConfig} className="w-full h-full">
			<PieChart width={radii[1] * 2} height={radii[1] + 20}>
				<Pie
					data={data}
					cx={"50%"}
					cy={"100%"}
					startAngle={angles[0]}
					endAngle={angles[1]}
					innerRadius={radii[0]}
					outerRadius={radii[1]}
					stroke="none"
					paddingAngle={0}
					dataKey="total"
					animationDuration={250}
					cornerRadius={4}
					accumulate="sum"
				>
					{data.map((_entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Tooltip
					content={<CustomTooltip active={false} payload={[]} />}
					animationDuration={150}
				/>
				{/* <ChartTooltip
					content={
						<ChartTooltipContent
							hideLabel
							className="w-40 bg-background/45 backdrop-blur-md"
						/>
					}
				/> */}
			</PieChart>
		</ChartContainer>
	);
};

export default DistributionChart;
