import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import React from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	XAxis,
	YAxis,
} from "recharts";
import { CardContent, CardFooter } from "../../../../components/ui/card";
import { useTheme } from "@/components/theme-provider";

const chartConfig = {
	value: {
		label: "P & L",
	},
} satisfies ChartConfig;

interface OverviewBar {
	data: any[];
	axesHidden?: boolean;
	skipZero?: boolean;
}

const OverviewBar: React.FC<OverviewBar> = ({
	data,
	axesHidden = false,
	skipZero = false,
}) => {
	// Your component logic here

	const { theme } = useTheme();
	const [min, max] = [
		Math.min(...data.map((d) => d.value)),
		Math.max(...data.map((d) => d.value)),
	];
	const padding = (max - min) * 0.1;

	const filteredData = skipZero
		? data.filter(
				(item) =>
					item.value !== 0 || item.value === undefined || item.value === null
		  )
		: data;

	console.log(filteredData, "filteredData");

	return (
		<>
			<ChartContainer config={chartConfig} className="h-[350px] w-full">
				<BarChart accessibilityLayer data={skipZero ? filteredData : data}>
					<CartesianGrid vertical={false} />
					<XAxis
						hide={axesHidden}
						dataKey="name"
						tickLine={false}
						tickMargin={10}
						axisLine={false}
						// domain={[min, max]}
						// scale={"log"}
						// tickFormatter={(value) => value.slice(0, 3)}
					/>
					<YAxis
						hide={axesHidden}
						tickLine={false}
						axisLine={false}
						tickMargin={10}
						tickCount={5}
						domain={[min - padding, max + padding]}
						// domain={["auto", "auto"]}
						scale={"sqrt"}
						// tickFormatter={(value) => `${value / 1000}k`}
					/>
					<ChartTooltip
						animationDuration={50}
						cursor={false}
						content={<ChartTooltipContent hideLabel />}
					/>
					<Bar dataKey="value" radius={4} animationDuration={500} fill="white">
						{filteredData.map((item) => (
							<Cell
								key={item.month}
								fill={
									item.value > 0
										? "hsl(var(--chart-green))"
										: "hsl(var(--chart-red))"
								}
							/>
						))}
					</Bar>
				</BarChart>
			</ChartContainer>
		</>
	);
};

export default OverviewBar;
