import { PlusIcon } from "lucide-react";
import React from "react";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useTradeStore from "@/redux/slice/tradeSlice";
const platforms = [
	{
		name: "MetaTrader 4",
		value: "mt4",
	},
	{
		name: "MetaTrader 5",
		value: "mt5",
	},
	{
		name: "Add Manual",
		value: "manual",
	},
];

interface AddTradeButtonProps {
	// Define your props here
	successCallback: () => void;
}

const AddTradeButton: React.FC<AddTradeButtonProps> = ({ successCallback }) => {
	const { fetchAllTrades, addTrades } = useTradeStore();
	const handleAddTrade = async (plat: string) => {
		// select file from file system
		// open file select dialog
		const input = document.createElement("input");
		input.type = "file";
		input.accept = ".html,.htm"; // Accept HTML files
		input.onchange = async (e) => {
			const file = (e.target as HTMLInputElement).files?.[0];
			if (file) {
				console.log(file);
			}
		};
		input.click();

		input.onchange = async (e) => {
			const file = (e.target as HTMLInputElement).files?.[0];
			if (file) {
				addTrades(file, plat).then(() => {
					fetchAllTrades();
					successCallback();
				});
			}
		};

		// parse file

		// console.log("Adding trade", plat);
		// console.log("Success callback", successCallback);
		// ParseTrades(plat)
		// 	.then(() => {
		// 		fetchAllTrades();
		// 		successCallback();
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button>
					<PlusIcon /> {` New Trade  `}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56">
				<DropdownMenuLabel>Add Trade</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					{platforms.map((plat: any) => (
						<DropdownMenuItem
							key={plat.value}
							onMouseDown={() => {
								handleAddTrade(plat.value);
							}}
						>
							{plat.name}
						</DropdownMenuItem>
					))}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default AddTradeButton;
