import { create } from "zustand";
import { models } from "@/types/interfaces";
import {
	GetAllTrades,
	GetTradeByUUID,
	CreateTrade,
} from "@/api-methods/trade-controller";
import { ParseTrades } from "@/api-methods/parse-trades";

interface TradeState {
	trades: models.Trade[];
	trade: models.Trade | null;
	error: string | undefined;
	loading: boolean;
	lastCreatedTrade: models.Trade | null;
	success: boolean;
	fetchAllTrades: () => Promise<void>;
	fetchTrade: (uuid: string) => Promise<void>;
	addTrades: (file: File, platform: string) => Promise<void>;
}

const useTradeStore = create<TradeState>((set) => ({
	trades: [],
	trade: null,
	error: undefined,
	loading: false,
	lastCreatedTrade: null,
	success: false,

	fetchAllTrades: async () => {
		set({ loading: true });
		console.log("Fetching all trades");

		try {
			const response = await GetAllTrades();
			console.log(response, "trade response");
			set({
				trades: response,
				loading: false,
				error: undefined,
				success: true,
			});
		} catch (error) {
			set({ error: (error as Error).message, loading: false });
		}
	},

	fetchTrade: async (uuid: string) => {
		try {
			const response = await GetTradeByUUID(uuid);
			console.log(response, "Trade");
			set({ trade: response });
		} catch (error) {
			set({ error: (error as Error).message });
		}
	},

	createTrade: async (trade: models.Trade) => {
		try {
			const response = await CreateTrade(trade);
			set((state) => ({
				trades: [...state.trades, response],
				lastCreatedTrade: response,
			}));
		} catch (error) {
			set({ error: (error as Error).message });
		}
	},

	addTrades: async (file: File, platform: string) => {
		try {
			const response = await ParseTrades(file, platform);
			console.log(response, "Trades");
		} catch (error) {
			set({ error: (error as Error).message });
		}
	},
}));

export default useTradeStore;
