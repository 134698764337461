"use client";

import React, { useState, useMemo } from "react";
import {
	Bell,
	BookOpen,
	ChevronLeft,
	ChevronRight,
	Pencil,
	Plus,
} from "lucide-react";
import {
	addDays,
	addMonths,
	addWeeks,
	eachDayOfInterval,
	eachHourOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	getHours,
	isSameDay,
	isSameMonth,
	parseISO,
	startOfDay,
	startOfMonth,
	startOfWeek,
	subMonths,
	subWeeks,
} from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import useDashboardStore from "@/redux/slice/dashboardSlice";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

type ViewType = "month" | "week";

export default function CalenderResponsive() {
	const { dashboardData, loading, error } = useDashboardStore();
	const [currentDate, setCurrentDate] = useState(new Date());
	const [view, setView] = useState<ViewType>("month");
	const today = new Date();

	const getDaysForMonth = (date: Date) => {
		const monthStart = startOfMonth(date);
		const monthEnd = endOfMonth(date);
		const calendarStart = startOfWeek(monthStart);
		const calendarEnd = endOfWeek(monthEnd);

		return eachDayOfInterval({ start: calendarStart, end: calendarEnd });
	};

	const getDaysForWeek = (date: Date) => {
		const weekStart = startOfWeek(date);
		const weekEnd = endOfWeek(date);

		return eachDayOfInterval({ start: weekStart, end: weekEnd });
	};

	const days =
		view === "month"
			? getDaysForMonth(currentDate)
			: getDaysForWeek(currentDate);

	const navigatePrevious = () => {
		setCurrentDate((prev) =>
			view === "month" ? subMonths(prev, 1) : subWeeks(prev, 1)
		);
	};

	const navigateNext = () => {
		setCurrentDate((prev) =>
			view === "month" ? addMonths(prev, 1) : addWeeks(prev, 1)
		);
	};

	const navigateToday = () => setCurrentDate(new Date());

	const getRandomAmount = () =>
		(Math.random() > 0.5 ? 1 : -1) * Math.floor(Math.random() * 5000);

	const profitByDate =
		dashboardData?.profit_by_days?.reduce((acc, item) => {
			// Parse the value to a number and check if it's NaN
			const value = parseFloat(item?.value?.toString() || "0");
			acc[item.name] = isNaN(value) ? 0 : value;
			return acc;
		}, {} as Record<string, number>) || {};
	const tradeCountByDate =
		dashboardData?.trades?.reduce((acc, trade) => {
			const date = format(parseISO(trade.open_date), "yyyy-MM-dd");
			acc[date] = (acc[date] || 0) + 1;
			return acc;
		}, {} as Record<string, number>) || {}; // Add this fallback

	const daysWithData = useMemo(() => {
		return days.map((day) => ({
			date: day,
			amount: profitByDate[format(day, "yyyy-MM-dd")] || 0, // Use 0 as default
			trades: tradeCountByDate[format(day, "yyyy-MM-dd")] || 0, // Also handle trade count
		}));
	}, [days, profitByDate, tradeCountByDate]);

	const renderMonthView = () => (
		<div className="grid grid-cols-7">
			{["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
				<div
					key={day}
					className="text-center font-semibold p-1 border border-foreground/10 sm:p-2 bg-secondary text-secondary-foreground text-xs sm:text-sm"
				>
					{day}
				</div>
			))}
			{daysWithData.map(({ date, amount, trades }) => {
				const isToday = isSameDay(date, today);
				return (
					<div
						key={date.toString()}
						className={cn(
							"relative aspect-square border",
							isSameMonth(date, currentDate)
								? "bg-background"
								: "bg-muted text-muted-foreground",
							isToday ? "border-blue-400 bg-blue-400/5" : "border-border",
							isSameMonth(date, currentDate) &&
								(amount < 0
									? "bg-rose-500/20"
									: amount > 0
									? "bg-emerald-500/20"
									: "")
						)}
					>
						<div className="absolute top-1 left-1 flex justify-between w-full pr-2 sm:pr-4">
							<span
								className={cn(
									"text-xs sm:text-sm md:text-base",
									isToday && "text-primary font-bold"
								)}
							>
								{format(date, "d")}
							</span>
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="ghost"
										size="icon"
										className="h-5 w-5"
										// onClick={() => setSelectedDate(day)}
									>
										<Plus className="h-3 w-3" />
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-32 p-2">
									<div className="flex items-center justify-between space-x-2">
										<Button variant="ghost" size="icon" className="h-6 w-6">
											<Pencil className="h-4 w-4" />
										</Button>
										<Button variant="ghost" size="icon" className="h-6 w-6">
											<Bell className="h-4 w-4" />
										</Button>
										<Button variant="ghost" size="icon" className="h-6 w-6">
											<BookOpen className="h-4 w-4" />
										</Button>
									</div>
								</PopoverContent>
							</Popover>
						</div>
						{isSameMonth(date, currentDate) && (
							<>
								<div
									className={cn(
										"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs sm:text-sm md:text-base lg:text-lg font-bold",
										amount >= 0 ? "text-emerald-500" : "text-rose-500"
									)}
								>
									{amount !== 0 && (amount > 0 ? "$" : "-$")}
									{amount !== 0 && Math.abs(amount).toFixed(2)}
								</div>
								<div className="hidden md:block absolute bottom-1 left-1 text-[8px] sm:text-xs text-muted-foreground">
									{trades > 0 && `${trades} trade(s) opened`}
								</div>
							</>
						)}
					</div>
				);
			})}
		</div>
	);

	const renderWeekView = () => {
		const hours = eachHourOfInterval({
			start: startOfDay(currentDate),
			end: addDays(startOfDay(currentDate), 1),
		});

		return (
			<div className="grid grid-cols-8 gap-1">
				<div className="text-center font-semibold p-1 sm:p-2 bg-secondary text-secondary-foreground text-xs sm:text-sm">
					Time
				</div>
				{days.map((day) => (
					<div
						key={day.toString()}
						className="text-center font-semibold p-1 sm:p-2 bg-secondary text-secondary-foreground text-xs sm:text-sm"
					>
						{format(day, "EEE d")}
					</div>
				))}
				{hours.map((hour) => (
					<React.Fragment key={hour.toString()}>
						<div className="text-center p-1 sm:p-2 bg-muted text-muted-foreground text-xs sm:text-sm">
							{format(hour, "HH:mm")}
						</div>
						{days.map((day) => (
							<div
								key={`${day.toString()}-${hour.toString()}`}
								className="border border-border p-1 sm:p-2 h-12 sm:h-16"
							>
								{/* Add event rendering logic here */}
							</div>
						))}
					</React.Fragment>
				))}
			</div>
		);
	};

	return (
		<div className="w-full h-full bg-background text-foreground p-2 sm:p-4">
			<div className="flex flex-col sm:flex-row items-center justify-between mb-4 space-y-2 sm:space-y-0">
				<h2 className="text-xl sm:text-2xl md:text-3xl font-bold">
					{format(currentDate, view === "month" ? "MMMM yyyy" : "MMMM d, yyyy")}
				</h2>
				<div className="flex items-center space-x-2">
					<Button variant="outline" size="icon" onClick={navigatePrevious}>
						<ChevronLeft className="h-4 w-4" />
					</Button>
					<Button
						variant="outline"
						onClick={navigateToday}
						className="text-xs sm:text-sm"
					>
						Today
					</Button>
					<Button variant="outline" size="icon" onClick={navigateNext}>
						<ChevronRight className="h-4 w-4" />
					</Button>
					<Select
						value={view}
						onValueChange={(value: ViewType) => setView(value)}
					>
						<SelectTrigger className="w-[120px]">
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="month">Month</SelectItem>
							<SelectItem value="week">Week</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
			{view === "month" ? renderMonthView() : renderWeekView()}
		</div>
	);
}
