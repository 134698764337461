"use client";

import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";

import { useTheme } from "@/components/theme-provider";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import { models } from "@/types/interfaces";

export const description = "A bar chart with a label";

const chartData = [
	{ month: "January", desktop: 186 },
	{ month: "February", desktop: 305 },
	{ month: "March", desktop: 237 },
	{ month: "April", desktop: 73 },
	{ month: "May", desktop: 209 },
	{ month: "June", desktop: 214 },
];

const chartConfig = {
	desktop: {
		label: "Desktop",
		color: "hsl(var(--chart-1))",
	},
} satisfies ChartConfig;

type ProfitLossChartProps = {
	data: models.Trade[];
};

export function ProfitLossChart({ data }: ProfitLossChartProps) {
	const { theme } = useTheme();

	const chartData = data
		.map((trade) => ({
			month: trade.open_date,
			profit: trade.profit,
		}))
		.reverse();

	const totalTrades = chartData.length;
	const totalProfit = chartData.reduce((acc, curr) => acc + curr.profit, 0);

	const [min, max] = [
		Math.min(...chartData.map((d) => d.profit)),
		Math.max(...chartData.map((d) => d.profit)),
	];
	const padding = (max - min) * 0.01;

	return (
		<Card className="h-full">
			<CardHeader>
				<CardTitle>Trade Results</CardTitle>
				<CardDescription>Across {totalTrades} trades</CardDescription>
			</CardHeader>
			<CardContent>
				<ChartContainer config={chartConfig} className="h-[135px] w-full">
					<BarChart
						accessibilityLayer
						data={chartData}
						margin={{
							top: 0,
							bottom: 0,
						}}
					>
						<CartesianGrid vertical={false} />
						<XAxis
							hide
							dataKey="month"
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							tickFormatter={(value) => value.slice(0, 3)}
						/>
						<YAxis
							hide
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							tickCount={5}
							domain={[min - padding, max + padding]}
						/>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel indicator="line" />}
						></ChartTooltip>
						<Bar
							dataKey="profit"
							fill={theme === "dark" ? "white" : "black"}
							radius={2}
						>
							{chartData.map((item) => (
								<Cell
									key={item.month}
									fill={
										item.profit > 0
											? "hsl(var(--chart-green))"
											: "hsl(var(--chart-red))"
									}
								/>
							))}
						</Bar>
					</BarChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
}
