import { models, controller } from "@/types/interfaces";

let API_BASE_URL: string | undefined;

console.log("Current NODE_ENV:", import.meta.env.MODE);

// In Vite, environment variables are prefixed with VITE_
console.log("VITE_BASEURL from env:", import.meta.env.VITE_BASEURL);

API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/trades` : undefined;

export async function GetAllTrades(): Promise<models.Trade[]> {
	try {
		if (!API_BASE_URL) {
			throw new Error("API_BASE_URL is not defined");
		}
		const response = await fetch(API_BASE_URL);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const trades = await response.json();
		return trades as models.Trade[];
	} catch (error) {
		console.error("Error fetching all trades:", error);
		throw error;
	}
}

export async function GetTradeByUUID(uuid: string): Promise<models.Trade> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const trade = await response.json();
		return trade as models.Trade;
	} catch (error) {
		console.error(`Error fetching trade with UUID ${uuid}:`, error);
		throw error;
	}
}

export async function CreateTrade(
	tradeData: Omit<models.Trade, "id">
): Promise<models.Trade> {
	try {
		if (!API_BASE_URL) {
			throw new Error("API_BASE_URL is not defined");
		}
		const response = await fetch(API_BASE_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(tradeData),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const newTrade = await response.json();
		return newTrade as models.Trade;
	} catch (error) {
		console.error("Error creating new trade:", error);
		throw error;
	}
}

export async function GetRunningPnl(
	uuid: string
): Promise<models.RunningPnl[]> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}/running-pnl`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const runningPnl = await response.json();
		return runningPnl as models.RunningPnl[];
	} catch (error) {
		console.error(
			`Error fetching running PNL for trade with UUID ${uuid}:`,
			error
		);
		throw error;
	}
}
