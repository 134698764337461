import { models } from "@/types/interfaces";

let API_BASE_URL: string | undefined;

console.log("Current NODE_ENV:", import.meta.env.MODE);

// In Vite, environment variables are prefixed with VITE_
console.log("VITE_BASEURL from env:", import.meta.env.VITE_BASEURL);

API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/back-tests` : undefined;

export async function GetAllBackTests(): Promise<models.BackTest[]> {
	try {
		const response = await fetch(`${API_BASE_URL}`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const backTests = await response.json();
		return backTests as models.BackTest[];
	} catch (error) {
		console.error("Error fetching back tests:", error);
		throw error;
	}
}

export async function GetBackTestByUUID(
	uuid: string
): Promise<models.BackTest> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const backTest = await response.json();
		return backTest as models.BackTest;
	} catch (error) {
		console.error(`Error fetching back test with UUID ${uuid}:`, error);
		throw error;
	}
}

export async function CreateBackTest(
	backTest: models.BackTest
): Promise<models.BackTest> {
	try {
		const response = await fetch(`${API_BASE_URL}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(backTest),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const createdBackTest = await response.json();
		return createdBackTest as models.BackTest;
	} catch (error) {
		console.error("Error creating back test:", error);
		throw error;
	}
}

export async function UpdateBackTest(
	backTest: models.BackTest
): Promise<models.BackTest> {
	try {
		const response = await fetch(`${API_BASE_URL}/${backTest.uuid}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(backTest),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const updatedBackTest = await response.json();
		return updatedBackTest as models.BackTest;
	} catch (error) {
		console.error(
			`Error updating back test with UUID ${backTest.uuid}:`,
			error
		);
		throw error;
	}
}

export async function DeleteBackTestByUUID(uuid: string): Promise<void> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}`, {
			method: "DELETE",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error) {
		console.error(`Error deleting back test with UUID ${uuid}:`, error);
		throw error;
	}
}

export async function AddProfitTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<void> {
	console.log(trade, "profit trade");
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}/add-profit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(trade),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error) {
		console.error(
			`Error adding profit trade to back test with UUID ${uuid}:`,
			error
		);
		throw error;
	}
}

export async function AddLossTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<void> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}/add-loss`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(trade),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error) {
		console.error(
			`Error adding loss trade to back test with UUID ${uuid}:`,
			error
		);
		throw error;
	}
}

export async function AddBreakEvenTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<void> {
	try {
		const response = await fetch(`${API_BASE_URL}/${uuid}/add-break-even`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(trade),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error) {
		console.error(
			`Error adding break even trade to back test with UUID ${uuid}:`,
			error
		);
		throw error;
	}
}

export async function DeleteMultipleBackTestsByUUID(
	uuids: string[]
): Promise<void> {
	try {
		const response = await fetch(`${API_BASE_URL}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(uuids),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error) {
		console.error("Error deleting multiple back tests:", error);
		throw error;
	}
}
