"use client";

import { TrendingUp } from "lucide-react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	Line,
	LineChart,
	XAxis,
	YAxis,
} from "recharts";

import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import { controller } from "@/types/interfaces";
import { useTheme } from "@/components/theme-provider";

export const description = "A line chart";

const chartConfig = {
	desktop: {
		label: "Desktop",
		color: "hsl(var(--chart-1))",
	},
} satisfies ChartConfig;

type AccountBalanceChartProps = {
	data: controller.DashboardData;
};

const formatDate = (date: string) => {
	const d = new Date(date);
	return d.toLocaleDateString("en-US", {
		month: "short",
		year: "numeric",
	});
};

const formatCurrency = (value: number) => {
	if (value >= 1000000) {
		return `$${(value / 1000000).toFixed(1)}M`;
	} else if (value >= 1000) {
		return `$${(value / 1000).toFixed(1)}K`;
	} else {
		return `$${value.toFixed(0)}`;
	}
};

export function AccountBalanceChart({ data }: AccountBalanceChartProps) {
	const { theme } = useTheme();

	const chartData =
		data?.account_balance_by_days?.map((item) => ({
			month: item.name,
			balance: item.value,
		})) || [];

	if (chartData.length === 0) {
		return (
			<Card>
				<CardHeader>
					<CardTitle>Account Balance</CardTitle>
					<CardDescription>No data available</CardDescription>
				</CardHeader>
			</Card>
		);
	}

	const minValue = Math.min(...chartData.map((d) => d.balance));
	const maxValue = Math.max(...chartData.map((d) => d.balance));
	const padding = (maxValue - minValue) * 0.2;
	const from = new Date(chartData[0].month);
	const to = new Date(chartData[chartData.length - 1].month);
	const yDomain = [minValue - padding, maxValue + padding];
	const gradientY2 = (yDomain[1] - minValue) / (yDomain[1] - yDomain[0]);

	return (
		<Card className="h-full">
			<CardHeader>
				<CardTitle>Account Balance</CardTitle>
				<CardDescription>
					{formatDate(from.toLocaleDateString())} -{" "}
					{formatDate(to.toLocaleDateString())}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<ChartContainer config={chartConfig} className="h-[135px] w-full">
					<AreaChart
						accessibilityLayer
						data={chartData}
						margin={{
							top: 0,
							bottom: 0,
						}}
					>
						<CartesianGrid vertical={false} />
						<defs>
							<linearGradient
								id="fillDesktop"
								x1="0"
								y1="0"
								x2="0"
								y2={`${gradientY2 * 100}%`}
							>
								<stop
									offset="5%"
									stopColor={theme === "dark" ? "white" : "black"}
									stopOpacity={0.8}
								/>
								<stop
									offset="95%"
									stopColor={theme === "dark" ? "black" : "white"}
									stopOpacity={0.1}
								/>
							</linearGradient>
						</defs>
						<XAxis
							dataKey="month"
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							tickFormatter={(value) =>
								new Date(value).toLocaleDateString("en-US", {
									// month: "short",
									day: "2-digit",
									month: "2-digit",
									// year: "numeric",
								})
							}
						/>
						<YAxis
							hide
							domain={[minValue - padding, maxValue + padding]}
							tickFormatter={(value) => formatCurrency(value)}
							allowDataOverflow={true}
						/>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
							formatter={(value) => formatCurrency(value as number)}
						/>
						<Area
							dataKey="balance"
							type="monotone"
							stroke={theme === "dark" ? "white" : "black"}
							strokeWidth={0.5}
							dot={false}
							fillOpacity={1}
							fill="url(#fillDesktop)"
						/>
					</AreaChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
}
