let API_BASE_URL: string | undefined;

console.log("Current NODE_ENV:", import.meta.env.MODE);

// In Vite, environment variables are prefixed with VITE_
console.log("VITE_BASEURL from env:", import.meta.env.VITE_BASEURL);

API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/parse` : undefined;

export async function ParseTrades(file: File, platform: string) {
	const formData = new FormData();
	formData.append("file", file, file.name);

	const response = await fetch(`${API_BASE_URL}/${platform}`, {
		method: "POST",
		headers: {
			accept: "application/json",
		},
		body: formData,
	});

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	return await response.json();
}
